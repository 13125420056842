@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.nav-container {
    background-image: linear-gradient(-45deg, #4f1e63, #662780);
    padding: 0px !important;
    text-align: center;
    background-color: purple;
    position: relative;
    height: 100%;
    width: 20%;
    float: left;
}

@media only screen and (min-height: 700px) {
    .nav-container {
        height: 100vh;
    }
  }

.body-container {
    position: relative;
    float: left;
    min-height: 100vh;
    max-height: 100vh;
    width: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px;
}

.home-bg{
    background-image: linear-gradient(-45deg, #4f1e63, #662780);

}

.nav-list {
    text-align: left;
}

.nav-item:hover {
    background: rgba(255, 255, 255, 0.08);
    padding: 15px 15px !important;
}

.nav-item {
    padding: 15px 10px !important;
    display: block;
    position: relative;
    transition: all ease-in-out 0.2s;
    font-size: 17px;
    cursor: pointer;
    color: white !important;
    border-bottom: 1px solid rgba(255,255,255,0.1);
}

.nav-item i {
    margin-right: 10px !important;
    margin-left: 10px !important;
    color: white !important;
}

.logo-promotor {
    height: 75px;
    position: relative;
    display: block;
    padding: 20px;
    text-align: left;
}
.logo-promotor img{
    width: 150px;

}
.logo-promotor i{
    position: relative;
    float: right;
    top: 50%;
    transform: translateY(-50%);
}
.subtitle-sistema {
    color: #fafafa;
    font-size: 16px;
    text-align: center;
}

.main-content {
    padding: 20px !important;
}

.item-promotor {
    padding: 10px !important;
    transition: all ease-in-out 0.2s;
    border-radius: 5px;
    border: none !important;
}

.item-promotor:hover {
    background: #eee;
}

.card-empresa{
    border-top: 5px solid #662780;
    width: 450px;
    text-align: center;
    text-transform: uppercase;
    color: #333;
    background-color: #fafafa;
}
.nome-empresa{
    line-height: 16px;
}
.nome-empresa span:first-child{
    font-size: 16px;
    font-weight: bold;
    display: block;
}
.nome-empresa span:last-child{
    font-size: 12px;
    font-weight: 300;
    display: block;
}

.imagem-quadrada{
    width: 100px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.imagem-circular{
    border-radius: 50%;
}

.telefone-empresa{
    font-weight: bold;
}

.card-promotor .ui.checkbox input:checked~.box:after, .card-promotor .ui.checkbox input:checked~label:after{
    font-family: Icons;
    font-style: normal;
    font-weight: 400;
    color: #EF5350;
    content: '\f00d';
}

.login-content{
    width: 350px;
    height: auto;
    margin: auto;
    position: relative;
    text-align: center;
}

.login-img{
    width: 300px;
    margin: auto;
    position: relative;
    margin-bottom: 100px;
}

#Pol_gono_1-login {
    fill: rgba(55,23,128,1);
}
.Pol_gono_1-login {
    transform: rotate(143deg);
    overflow: visible;
    position: absolute;
    top: 452.924px;
    left: 180.654px;
    width: 531.687px;
    height: 521.452px;
}
#Pol_gono_2-login {
    fill: rgba(146,30,232,1);
}
.Pol_gono_2-login {
    transform: rotate(224deg);
    overflow: visible;
    position: absolute;
    top: 94.139px;
    left: 1178.345px;
    width: 532.305px;
    height: 531.022px;
}
.header-padrao{
    background-image: linear-gradient(-45deg, #662780, #4f1e63);
    padding: 10px 30px;
    color: #fafafa;
    width: auto;
    display: inline-block;
    position: relative;
    left: -14px;
    border-radius: 0px 50px 50px 0px;
}
.header-padrao span:first-child{
    font-size: 16px;
    font-weight: bold;
    display: block;
}
.header-padrao span:last-child{
    font-size: 14px;
    display: block;
}
.header-padrao .icone{
    display: inline-block;
    transform: translateY(-50%);
    margin-right: 10px;
}
.header-padrao-dad .ui.button{
    float: right;
    margin-top: 10px;
}
.cancelar{
    color: #EF5350;
}
.botao-texto{
    cursor: pointer;
    text-align: center;
    padding-top: 10px;
}
.radio-group{
    width: 150px;
    text-align: left;
    position: relative;
    margin: auto;
    margin-bottom: 20px;
}
.radio-group .ui.radio.checkbox{
    display: block;
    margin-top: 10px;
}
.adm-foto{
    display: block;
}
.adm-foto .imagem-quadrada{
    margin: auto;
    transform: translateY(50%)
}
.adm-info{
    display: block;
    text-align: center;
    border: 1px solid #662780;
    border-radius: 20px;
    padding: 10px;
    padding-top: 60px;
}
.adm-info span:first-child{
    font-weight: bold;
    font-size: 16px;
}
.adm-info span{
    display: block;
    font-size: 12px;
}
.erros-cadastro{
    color: #EF5350;
    padding: 10px;
    margin: 10px;
    border-radius: 50px;
    border: 1px solid #EF5350;
}
.perfil-menu{
    text-align: center;
    display: block;
    position: relative;
    background: #fafafa;
    padding: 20px;
    width: 100%;
}
.logo-loading-container {
    width: 96px;
    height: 96px;
    position: relative;
    margin: 0 auto;
}

.loading-container {
    border-radius: 50%;
    width: 96px;
    height: 96px;
}

.logo-loading {
    position: absolute;
    background-color: rgba(102, 39, 128, .5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: logo-transition 2s infinite;
}

.logo-loading2 {
    position: absolute;
    background-color: rgba(102, 39, 128, .5);
    animation: flippe-logo-transition 2s infinite;
}

.logo-loading-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.not-found {
    display: flex;
    flex-direction: column;
    height: 100vh;
    text-align: center;
    justify-content: center;
    justify-items: center;
}

@keyframes logo-transition {
    0% {
        transform: scale(1.5, 1.5);
    }
    50% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1.5, 1.5);
    }
}

@keyframes flippe-logo-transition {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.5, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}
.nome-usuario-logado{
    display: block;
    color: #662780;
    text-transform: uppercase;
    font-size: 18px;
    padding: 15px;
    font-weight: bold;
}

.header-aprovacao{
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    background: linear-gradient(-45deg, #662780, #4f1e63);
    color: #fafafa;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    display: table;
}
.header-aprovacao a i.icon{
    margin: 0px !important;
    color: #fafafa;
}
.header-aprovacao a {
    float: right;
    padding: 10px;
    position: relative;
    display: block;
    text-decoration: none;
    transition: ease-in-out 0.2s;
}
.header-aprovacao a:hover{
    background: rgba(146, 30, 232, 0.5)
}

::-webkit-scrollbar {
    width: 5px;
}
  
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background:  #662780; 
}

input[type=text].valor-input {
    border: none !important;
}
.valor-input {
    text-align: center;
    color: #662780 !important;
    font-size: 50px !important;
    font-weight: bold !important;
    line-height: 1rem !important;
    padding:  15px 15px !important;
    outline: none;
    width: 100%;
}
.transfers{
    cursor: pointer;
    color: #333;
    border-bottom: 1px solid #eee;
}
.sent-or-received, .transfers .data-t{
    text-transform: uppercase;
    font-size: 12px;
    display: block;
}
.sender-receiver, .transfers .data{
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
}
.transfers .value{
    font-weight: bold;
    font-size: 1.5em;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    display: block;
}
.sub-info{
    display: block;
    font-size: 12px;
    color: #999;
}
.download-table-xls-button {
    background-color: #662780;
    border: none;
    border-radius: 3px;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
  }
  