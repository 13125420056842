.header-list {
    color: #662780  !important;
}

.foto-empresa{
    width: 300px;
    height: 300px;
    background: #333;
    display: inline-block;
    margin: auto;
    background-position: center;
    background-size: cover;
}

.foto-empresa.circular{
    border-radius: 50%;
}
