.ui.button, .ui.basic.button{
    padding: 1em 1em;
}
.ui.purple.button, .ui.purple.buttons .button{
    background-image: linear-gradient(-45deg, #4f1e63, #662780);
    transition: all ease-in-out 0.3s;
}
.ui.basic.purple.button, .ui.basic.purple.buttons .button {
    box-shadow: 0 0 0 1px #662780 inset!important;
    color: #662780!important;
}
.ui.basic.purple.button:hover, .ui.basic.purple.buttons .button:hover {
    box-shadow: 0 0 0 1px #4f1e63 inset!important;
    color: #4f1e63!important;
}
.ui.purple.button:hover, .ui.purple.buttons .button:hover{
    background-image: linear-gradient(-45deg, #4f1e63, #4f1e63);
}
