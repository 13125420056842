.ui.modal>.header{
    border-bottom: 0px;
    text-align: center;
    text-transform: uppercase;
    color: #371780;
    font-weight: bold;
    font-family: Roboto;
}
.ui.modal{
    border-radius: 30px;
    overflow: hidden;
}
.ui.small.modal>.header:not(.ui){
    font-size: 24px;
}