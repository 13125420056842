.file-uploader {
  flex: 0 1 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 5px;
}
#file-input {
  display: none;
}

label[for="file-input"] {
  padding: 1em 1.5em;
  flex: 1 1 auto;
  text-align: center;
  color: #FFF;
  cursor: pointer;
}
  
.image-preview {
  margin: 0 0 1rem;
  min-height: 250px;
  flex: 1 0 75%;
  background-color: #c1c1c1;
}

.hover-imagem:hover {
  color: pink;
  background-color:rgba(0,0,0,0.6);
}

.hover-imagem {
  position: absolute;
  z-index: 99;
  color: rgba(0,0,0,0);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color:rgba(0,0,0,0);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 17px;
}